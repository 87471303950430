import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import {
Grid,
Select,
MenuItem,
Dialog,
DialogTitle,
DialogContent,
DialogActions,
Button,
Typography,
Backdrop,
CircularProgress,
} from '@mui/material/';
import useDashboardAccount from '../Context/useDashboardAccount';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function SessionAttachSelect(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    customer_uuid,
    projects
  } = useDashboardAccount();

  const [selected, setSelected] = useState(
    props.sub.session_id_attached_to ?
      props.sub.session_id_attached_to :
      'none'
  );
  const [isOpen, setIsOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const changeSelection = event => {
    setSelected(event.target.value);
      setIsOpen(true);
  }

  const cancelSelection = event => {
    setSelected(
      props.sub.session_id_attached_to ?
        props.sub.session_id_attached_to :
        'none'
    );
    setIsOpen(false);
  }

  const dialogInfo = () => {
    let project = projects?.find(proj => proj._id === selected)
    if (project && project.project_name) {
      return 'Would you like to attach this subscription to ' + project.project_name
    } else if (selected === 'none') {
      return 'This will remove the subscription from all projects'
    }
  }

  const attachToSession = async () => {
    setInProgress(true);
    let postData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify({
        subscription_id: props.sub._id,
        session_id: selected,
      }),
    }

    try {
      let response = await fetch(process.env.REACT_APP_API_URL + '/api/producer_subs/attach/' + customer_uuid, postData);
      let resJson = await response.json();
      enqueueSnackbar(resJson.message, { variant: resJson.type });
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
    }

    setInProgress(false);
    setIsOpen(false);
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Select
          value={selected}
          displayEmpty
          onChange={e => changeSelection(e)}
        >
          {
            selected === 'none' &&
            <MenuItem value="none" disabled>
              Attach to Project
            </MenuItem>
          }
          {
            selected != 'none' &&
            <MenuItem value='none'>
              Remove from Project
            </MenuItem>
          }
          {
            projects?.map(proj => (
              <MenuItem value={proj._id} key={proj._id}>{proj.project_name}</MenuItem>
            ))
          }
        </Select>
      </Grid>
      <Dialog
        open={isOpen}
        onClose={cancelSelection}
      >
        <DialogTitle className={props.cssClasses.dtitle}>
          <Typography>
            Attach to Project
          </Typography>
        </DialogTitle>

        <DialogContent className={props.cssClasses.dcontent}>
          <Typography>
            {dialogInfo()}
          </Typography>
          <Typography>
            {
              selected != 'none' &&
              'This will pause all active streams on this project until manually restarted.'
            }
          </Typography>
        </DialogContent>

        <DialogActions className={props.cssClasses.dactions}>
          <Button
            variant="contained"
            onClick={cancelSelection}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={attachToSession}
          >
            Confirm
          </Button>
        </DialogActions>

      </Dialog>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );

}
